import { useEffect, useRef, useState } from 'react';
import {
  // createUserWithEmailAndPassword,
  fetchSignInMethodsForEmail,
  sendEmailVerification,
  sendPasswordResetEmail,
  signOut,
  UserCredential,
} from 'firebase/auth';
import { Eye, EyeIcon, EyeOff } from 'lucide-react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import * as z from 'zod';

import CsLogo from '@/assets/CSLogo.svg';
import googleLogo from '@/assets/googleLogo.png';
import Logo from '@/assets/Logo.svg?react';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog';
import { Button } from '@/components/ui/button';
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { ToastAction } from '@/components/ui/toast';
import { useToast } from '@/components/ui/use-toast';
import config from '@/config';
import { companyNameAndLogo } from '@/const';
import { UpdateUserFirebasePasswordDocument } from '@/lib/__generated__/dashboard/graphql';
import { client, ClientName } from '@/lib/apollo';
import { auth } from '@/lib/firebase';
import {
  checkUserExists,
  // createFirebaseUserWithPassword,
  signInWithGoogle,
  signInWithPassword,
} from '@/lib/helpers/auth';
import useBoundStore from '@/store';
import { zodResolver } from '@hookform/resolvers/zod';

import { Icons } from '../icons';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '../ui/dialog';
import { Label } from '../ui/label';

const FormSchema = z.object({
  email: z
    .string()
    .email({ message: 'Invalid email address' })
    .min(1, { message: 'Email is required' }),
  password: z.string().min(1, { message: 'Password is required' }),
});

const UpdatePasswordFormSchema = z
  .object({
    password: z.string().min(6, { message: 'Password must be at least 6 characters' }),
    confirm_password: z.string().min(1, { message: 'Confirm Password is required' }),
  })
  .refine((data) => data.password === data.confirm_password, {
    path: ['confirm_password'],
    message: 'Passwords do not match',
  });

const Signin = () => {
  const setAuthChecked = useBoundStore((state) => state.authSlice.setAuthChecked);
  const firebaseAuthChecked = useBoundStore((state) => state.authSlice.firebaseAuthChecked);
  const navigate = useNavigate();
  const { toast, dismiss: dismissToast } = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [showConfirmPass, setShowConfirmPass] = useState(false);
  const [showUpdatePass, setShowUpdatePass] = useState(false);
  const [showWaitForEmailVerifyAlert, setShowWaitForEmailVerifyAlert] = useState(false);
  const [emailVerifiedAlert, setEmailVerifiedAlert] = useState(false);
  const [showForgetPasswordInput, setShowForgetPasswordInput] = useState(false);

  const togglePasswordShowHide = () => {
    setShowPass((prev) => !prev);
  };

  const toggleUpdatePasswordShowHide = () => {
    setShowConfirmPass((prev) => !prev);
  };

  const handleError = (errorMessage: string) => {
    toast({
      title: 'Error',
      variant: 'destructive',
      description: errorMessage,
    });
  };
  const form = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
    resolver: zodResolver(FormSchema),
  });

  const updatePassform = useForm({
    defaultValues: {
      password: '',
      confirm_password: '',
    },
    resolver: zodResolver(UpdatePasswordFormSchema),
  });

  const checkEmailVerification = async (googleLoginResUser?: UserCredential['user']) => {
    console.debug('🦊 | checkEmailVerification | googleLoginResUser:', googleLoginResUser);
    const authUser = googleLoginResUser ?? auth?.currentUser;
    try {
      if (authUser && (!authUser?.emailVerified || config.sendSignupEmailVerificationAlways)) {
        await sendEmailVerification(authUser);
        if (config.waitForEmailVerified) {
          setShowWaitForEmailVerifyAlert(true);
        } else {
          toast({
            title: 'Email verification sent',
            description: 'Please click the link in the email to verify',
          });
        }
      }
      if (!config.waitForEmailVerified || authUser?.emailVerified) {
        dismissToast();
        console.debug('navigating to /home');
        navigate('/home');
      } else if (config.waitForEmailVerified) {
        // const userCreds_ = await signInWithEmailAndPassword(auth, data.email, password);
        // console.debug('🦊 | onSubmit | userCreds_:', userCreds_);
        // loop check if email is verified or wait for the hook?
        let count = 0;
        const timer = setInterval(async () => {
          console.debug(
            `waitForEmailVerified interval. Something is wrong if this doesn't stop loopin.`,
          );
          auth?.currentUser?.reload();
          count += 1;
          if (auth?.currentUser?.emailVerified) {
            setShowWaitForEmailVerifyAlert(false);
            clearInterval(timer);
            setEmailVerifiedAlert(true);
            const timer2 = setTimeout(() => {
              console.debug('navigating to /home');
              navigate('/home');
              clearTimeout(timer2);
            }, 2000);
          } else if (count === 5) {
            clearInterval(timer);
          }
        }, 2000);
      }
    } catch (err: any) {
      console.error('🦊 | checkEmailVerification | err:', err);
      // check if should also signout from firebase
      toast({
        variant: 'destructive',
        description: err.message,
      });
    }
  };

  // const handleCreateFirebaseUser = async (
  //   email,
  //   password,
  // ): Promise<UserCredential | undefined> /* success*/ => {
  //   try {
  //     const userCreds = await createFirebaseUserWithPassword(email, password);
  //     console.debug('🦊 | handleCreateFirebaseUser | userCreds:', userCreds);
  //     return userCreds;
  //   } catch (err: any) {
  //     if (err?.message) {
  //       form.setError('password', { message: '' });
  //       handleError(err.message);
  //     }
  //   }
  // };

  const onSubmitSigninWithPassword = async (data) => {
    // setAuthChecked(false);
    console.debug('🦊 | onSubmit | data:', data);

    try {
      setIsLoading(true);
      await checkUserExists(data.email);
      const passwordLoginRes = await signInWithPassword(data.email, data.password);
      console.debug('🦊 | onSubmitSigninWithPassword | passwordLoginRes:', passwordLoginRes);
      setIsLoading(false);

      if (data.password.slice(0, 3) === config.tempPasswordPrefix) {
        setShowUpdatePass(true);
        updatePassform.reset();
        return;
      }

      checkEmailVerification(passwordLoginRes?.user);

      // navigate('/home');
    } catch (err: any) {
      setIsLoading(false);
      console.debug('🦊 | onSubmitSigninWithPassword | err:', err.message);
      let message: string;
      // if (err.message?.includes('Firebase: Error (auth/user-not-found)')) {
      //   const userCreds = await handleCreateFirebaseUser(data.email, data.password);
      //   checkEmailVerification(userCreds?.user);
      //   // navigate('/home');
      // } else
      if (err.message?.includes('Firebase: Error (auth/wrong-password).')) {
        const theAuth = await fetchSignInMethodsForEmail(auth, data.email);
        console.debug('🦊 | onSubmitSigninWithPassword | theAuth:', theAuth);
        if (theAuth.length === 1 && theAuth.includes('google.com')) {
          message = 'This email was used with the Google login. Please login with Google.';
        } else {
          message = 'Your email or password is incorrect. Please try again.';
          // TODO: red the password box
          form.setError('password', { message: 'Incorrect password' });
        }
      } else {
        message = err.message;
      }
      if (message) handleError(message);
    }
  };

  const onSubmitUpdatePassword = async (data: { password: string; confirm_password: string }) => {
    console.log(data);
    const {
      data: { updateUserFirebasePassword: ret },
    } = await client.mutate({
      mutation: UpdateUserFirebasePasswordDocument,
      variables: {
        password: data.password,
      },
      context: { clientName: ClientName.Dashboard },
    });
    console.log(ret);

    dismissToast();
    console.debug('navigating to /home');
    navigate('/home');
  };

  const onInvalid = (field) => {
    console.debug('🦊 | onInvalid | field:', field);
  };

  const handleSigninWithGoogle = async (e) => {
    // setAuthChecked(false);
    try {
      setIsLoading(true);
      const googleLoginRes: any = await signInWithGoogle();
      console.log('🦊 | handleSigninWithGoogle | googleLoginRes:', googleLoginRes);
      setIsLoading(false);
      // navigate('/home');
      checkEmailVerification(googleLoginRes?.user);
    } catch (err: any) {
      console.log({ err });
      setIsLoading(false);
      if (err?.message) handleError(err.message);
      if (auth?.currentUser) {
        signOut(auth);
        localStorage.clear();
      }
    }
  };

  // auto redirect to home if already had a token
  // TODO: might want to check if token is expired
  // useEffect(() => {
  //   if (localStorage.getItem('adminToken')) {
  //     navigate('/home');
  //   }
  // }, []);

  // sign out from google if there is no adminToken
  useEffect(() => {
    if (firebaseAuthChecked && auth?.currentUser && !localStorage.getItem('adminToken')) {
      console.debug(
        '😶 | calling firebase signout because there is currentUser but there is no adminToken',
      );
      auth.signOut();
    }
  }, [firebaseAuthChecked]);

  // **************** reset password *************************
  useEffect(() => {
    if (location.pathname === '/reset-password') {
      // need to setTimeout 0 to wait for the Toaster to mount before trying to toast
      setTimeout(() => {
        toast({
          title: 'Success!',
          description: 'Your password has been successfully reset.',
          action: <ToastAction altText="Sign in">Sign in</ToastAction>,
        });
        navigate('/signin');
      }, 0);
    }
  }, [location.pathname]);
  // *********************************************************

  const renderEmailPassword = () => (
    <form
      onSubmit={form.handleSubmit(onSubmitSigninWithPassword, onInvalid)}
      className="grid w-full items-center gap-4"
    >
      <div className="grid gap-2">
        <FormField
          control={form.control}
          name={'email'}
          render={({ field: hookField }) => (
            <FormItem className={'flex flex-col space-y-1.5'}>
              <FormLabel>Email</FormLabel>
              <FormControl>
                <Input placeholder="Enter your email" {...hookField} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="password"
          render={({ field: hookField }) => (
            <FormItem className={'flex flex-col space-y-1.5'}>
              <FormLabel>Password</FormLabel>
              <FormControl>
                <div className="relative">
                  <Input
                    placeholder="Enter your password"
                    type={showPass ? 'text' : 'password'}
                    {...hookField}
                  />
                  {/* TODO: add show/hide */}
                  {showPass ? (
                    <Eye
                      className="absolute right-3 top-3 cursor-pointer"
                      onClick={togglePasswordShowHide}
                    />
                  ) : (
                    <EyeOff
                      className="absolute right-3 top-3 cursor-pointer"
                      onClick={togglePasswordShowHide}
                    />
                  )}
                </div>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <Button disabled={isLoading} className="mt-[30px]">
          {isLoading && <Icons.spinner className="mr-2 size-4 animate-spin" />}
          Sign In
        </Button>
      </div>
    </form>
  );

  const renderUpdatePassword = () => (
    <form
      onSubmit={updatePassform.handleSubmit(onSubmitUpdatePassword, onInvalid)}
      className="grid w-full items-center gap-4"
    >
      <div className="grid gap-2">
        <FormField
          control={updatePassform.control}
          name="password"
          render={({ field: hookField }) => (
            <FormItem className={'flex flex-col space-y-1.5'}>
              <FormLabel>Password</FormLabel>
              <FormControl>
                <div className="relative">
                  <Input
                    placeholder="Enter your password"
                    type={showPass ? 'text' : 'password'}
                    {...hookField}
                  />
                  {/* TODO: add show/hide */}
                  {showPass ? (
                    <Eye
                      className="absolute right-3 top-3 cursor-pointer"
                      onClick={togglePasswordShowHide}
                    />
                  ) : (
                    <EyeOff
                      className="absolute right-3 top-3 cursor-pointer"
                      onClick={togglePasswordShowHide}
                    />
                  )}
                </div>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={updatePassform.control}
          name="confirm_password"
          render={({ field: hookField }) => (
            <FormItem className={'flex flex-col space-y-1.5'}>
              <FormLabel>Confirm Password</FormLabel>
              <FormControl>
                <div className="relative">
                  <Input
                    placeholder="Enter your password"
                    type={showConfirmPass ? 'text' : 'password'}
                    {...hookField}
                  />
                  {/* TODO: add show/hide */}
                  {showConfirmPass ? (
                    <Eye
                      className="absolute right-3 top-3 cursor-pointer"
                      onClick={toggleUpdatePasswordShowHide}
                    />
                  ) : (
                    <EyeOff
                      className="absolute right-3 top-3 cursor-pointer"
                      onClick={toggleUpdatePasswordShowHide}
                    />
                  )}
                </div>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <Button disabled={isLoading} className="mt-[30px]">
          {isLoading && <Icons.spinner className="mr-2 size-4 animate-spin" />}
          Update Password
        </Button>
      </div>
    </form>
  );

  const renderGoogleButton = () => (
    <>
      <Button
        variant="outline"
        type="button"
        disabled={isLoading}
        onClick={handleSigninWithGoogle}
        className="normal-case"
      >
        {isLoading ? (
          <Icons.spinner className="mr-2 size-4 animate-spin" />
        ) : (
          <img src={googleLogo} className="mr-2 size-6 " />
        )}
        Sign in with Google
      </Button>
      <div className="relative">
        <div className="absolute inset-0 flex items-center">
          <span className="w-full border-t border-input" />
        </div>
        <div className="relative flex justify-center text-xs uppercase">
          <span className="bg-card px-2 text-muted-foreground">Or continue with</span>
        </div>
      </div>
    </>
  );

  return (
    <div className="container flex h-screen flex-col items-center justify-center ">
      <div className="absolute top-0 mt-[50px] flex text-2xl loginMaxHeight:relative loginMaxHeight:mb-[20px]">
        <img className="h-[32px]" src={companyNameAndLogo.logo} />
        {/*<h3 className="ml-[20px] text-[22px] font-semibold uppercase">{companyNameAndLogo.name}</h3>*/}
      </div>
      <Card className="w-full max-w-[550px] rounded-[24px] border-0 p-[48px] pt-[30px] shadow-lg">
        <CardHeader className="flex flex-col items-center p-[0px] text-center">
          <CardTitle className="font-semibold">Welcome to {companyNameAndLogo.name}</CardTitle>
          <CardDescription className="w-[250px] pb-[30px] pt-[14px]">
            {showUpdatePass
              ? 'Please update your password'
              : 'Enter your email below in order to sign in to your account.'}
          </CardDescription>
        </CardHeader>
        <CardContent className="p-0">
          <div className="grid w-full items-center gap-4">
            {!showUpdatePass && renderGoogleButton()}
            {showUpdatePass ? (
              <Form {...updatePassform} key="updatePassword">
                {renderUpdatePassword()}
              </Form>
            ) : (
              <Form {...form} key="signinForm">
                {renderEmailPassword()}
              </Form>
            )}
          </div>
        </CardContent>
      </Card>
      {/* only allow loading signup if already has firebase signed in -> so not showing sing up link */}
      {/* <div className="mt-4 text-center">
        <p className="mr-2 inline">Don't have an account?</p>
        <Link to="/signup">Sign up</Link>
      </div> */}

      {/*  */}
      <div className="mt-4 text-center">
        <p className="text-md inline">Forget your password? </p>
        <Button
          className="text-md capitalize"
          variant="link"
          onClick={(e) => {
            setShowForgetPasswordInput(true);
          }}
        >
          Reset Password
        </Button>
      </div>

      {/* alerts */}
      <AlertDialog open={showWaitForEmailVerifyAlert}>
        <AlertDialogContent className="bg-card">
          <AlertDialogHeader>
            <AlertDialogTitle>Email verfification sent</AlertDialogTitle>
            <AlertDialogDescription>Please verify your email to proceed</AlertDialogDescription>
          </AlertDialogHeader>
        </AlertDialogContent>
      </AlertDialog>

      <AlertDialog open={emailVerifiedAlert}>
        <AlertDialogContent className="bg-card">
          <AlertDialogHeader>
            <AlertDialogTitle>Email verified</AlertDialogTitle>
            <AlertDialogDescription>Signing you in</AlertDialogDescription>
          </AlertDialogHeader>
        </AlertDialogContent>
      </AlertDialog>

      <ForgetPasswordDialog
        open={showForgetPasswordInput}
        handleClose={() => {
          setShowForgetPasswordInput(false);
        }}
        handleError={handleError}
      />
    </div>
  );
};

const ForgetPasswordDialog = ({ open, handleClose, handleError }) => {
  const form = useForm({
    defaultValues: {
      email: '',
    },
    resolver: zodResolver(
      z.object({
        email: z
          .string()
          .email({ message: 'Invalid email address' })
          .min(1, { message: 'Email is required' }),
      }),
    ),
    mode: 'onChange',
  });
  const { isValid } = form.formState;
  const { toast } = useToast();
  const handleSubmitResetPassword = async (data) => {
    try {
      const email = data.email;
      console.debug('🦊 | handleSubmitResetPassword | email:', email);
      const sendPasswordResetEmailRes = await sendPasswordResetEmail(auth, email, {
        url: `${window.location.origin}/reset-password`,
        handleCodeInApp: true,
      });
      console.log({ sendPasswordResetEmailRes });
      // TODO: might change to a popup instead of just a toast
      toast({
        title: 'Please check your email',
        description: 'A reset password link has been sent.',
      });
      handleClose();
    } catch (resetPassErr: any) {
      console.log({ resetPassErr });
      if (resetPassErr.code === 'auth/user-not-found') {
        handleError('User not found');
      }
      // handleClose();
    }
  };

  return (
    <Dialog
      open={open}
      onOpenChange={(open) => {
        if (!open) handleClose();
      }}
    >
      <DialogContent className="rounded-[20px] sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Reset Password</DialogTitle>
          <DialogDescription>
            Input your email that you want to receive the reset password link here.
          </DialogDescription>
        </DialogHeader>
        {/* TODO: make it a form. add validate and handleSubmit */}
        <form onSubmit={form.handleSubmit(handleSubmitResetPassword)}>
          <div className="grid gap-y-1">
            <Label htmlFor="email" className="">
              Email
            </Label>
            <Input id="email" className="" {...form.register('email')} />
          </div>
          <DialogFooter className="mt-2">
            {/* TODO: add loading when is submitting */}
            <Button type="submit" disabled={!isValid}>
              Submit
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default Signin;
